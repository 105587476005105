@import url('https://fonts.googleapis.com/css?family=Fira+Sans+Condensed:400,700|Lobster&display=swap');

// font-family: 'Fira Sans Condensed', sans-serif;
// font-family: 'Lobster', cursive;

html {
    font-size: 62.5%;
}

body {
    background: #F0F0F0;
    font-family: 'Fira Sans Condensed',
        sans-serif;
    font-size: 16px;
    color: #3C3C3C;
    letter-spacing: .08rem;
    line-height: 1.5;
    height: 100vh;
    display: flex;
    flex-direction: column;

    img {
        display: block;
        width: 100%;
    }

    a {
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }
    }

    ul {
        list-style-type: none;
    }

    main {
        margin-top: calc(6.8rem + 2rem);
    }
}

// For sticky footer
.flex-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    main {
        flex: 1;
    }
}