@mixin btn-hover {
    transition: all .5s ease-in-out;
}

@mixin trans3 {
    transition: all .3s ease-in-out;
}

@mixin trans4 {
    transition: all .4s ease-in-out;
}

@mixin trans5 {
    transition: all .5s ease-in-out;
}

@mixin trans6 {
    transition: all .6s ease-in-out;
}

@mixin trans7 {
    transition: all .7s ease-in-out;
}