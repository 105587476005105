// ******* Typography *******

$clr-darkGrey: #101010;
$clr-lightGrey: #3C3C3C;
$clr-lighterGrey: #777777;
$clr-white: #F5F5F5;
$clr-footer: rgba(16, 16, 16, 0.7);

// ******* Main Colours

$clr-lightTeal: #30C2B9;
$clr-darkTeal: #165D69;
$clr-darkTealGradient: #217f8f;


// ******* Logo Colour

$clr-logo: #202C2E;

// ****** Font Styles

$ff-site-header: 5rem 'Fira Sans Condensed',
sans-serif;
$ff-body-txt:'Fira Sans Condensed',
sans-serif;
$ff-quote:'Lobster',
cursive;

// ****** Font Weight

$fw-normal: 400;
$fw-bold: 700;